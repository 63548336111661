import simpleRestProvider from "ra-data-simple-rest";
import { HttpError } from "react-admin";
import Endpoints from "../constants/Endpoints";
import { diff } from "../utils/diff";
import getApiUrl from "../utils/getApiUrl";
import axiosInstance from "./api";

const dataProvider: any = simpleRestProvider(
  process.env.REACT_APP_API_URL + "",
  axiosInstance
);

export const dataProviderListo: any = {
  ...dataProvider,
  getList: (resource, params) => {
    switch (resource) {
      case "suppliers":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/suppliers?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page +
                (params.filter.search
                  ? "&search=" + params.filter.search
                  : "") +
                (params.filter.has_official_catalog
                  ? "&filter.has_official_catalog=" +
                    params.filter.has_official_catalog
                  : "") +
                "&sortBy=created_at:DESC"
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { data } = clientData;
              try {
                json = { data, total: clientData.meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurant-supplier-alt":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              process.env.REACT_APP_API_URL +
                `/restaurants-suppliers?filter.supplier_id=${params.id}&limit=10&page=${params.pagination.page}`
            )
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              const dataWithId = data.map((relation) => ({
                ...relation,
                id: `${relation.supplier_id}|${relation.restaurant_id}|${relation.chat_channel_url}`,
              }));
              try {
                json = { data: dataWithId, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "products":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/products/all?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page
                +
                (params.filter.search
                  ? "&search=" + params.filter.search
                  : "")
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { data, meta } = clientData;
              try {
                json = { data, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "meta-products":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/meta-products?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page
                +
                (params.filter.search
                  ? "&search=" + params.filter.search
                  : "")
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { data, meta } = clientData;
              console.log(  data);
              try {
                json = { data, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "orders":
        // Default sort parameter
        const sort = params.sort || { field: "id", order: "DESC" };

        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/orders?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page +
                (params.filter.search
                  ? "&search=" + params.filter.search
                  : "") +
                (params.filter.status
                  ? "&filter.status=" + params.filter.status
                  : "") +
                (params.sort ? `&sortBy=${sort.field}:${sort.order}` : "")
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { meta, data } = clientData;
              try {
                json = { data, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "allergens":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/allergens?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { meta, data } = clientData;
              try {
                json = { data, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "product-categories":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/product-categories?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { meta, data } = clientData;
              try {
                json = { data, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "product-units":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/products-measurement-units?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              try {
                json = {
                  data: clientData.map((curr) => ({
                    id: curr.value,
                    name: curr.name,
                  })),
                  total: clientData.length,
                };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurants":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/restaurants?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page +
                (params.filter.search
                  ? "&search=" + params.filter.search
                  : "") +
                "&sortBy=created_at:DESC"
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { data } = clientData;
              try {
                json = { data, total: clientData.meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "supplier-catalog":
        return new Promise((resolve, reject) => {
          const url =
            process.env.REACT_APP_API_URL +
            Endpoints.SUPPLIER_MASTER_CATALOG +
            `?filter.supplier_id=${params.filter.supplier_id}&limit=${params.pagination.perPage}&page=${params.pagination.page}`;

          const searchUrl = params.filter.search
            ? `&search=${params.filter.search}`
            : "";

          axiosInstance
            .get(url + searchUrl)
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              const dataWithId = data.map((product) => ({
                id: product.id,
                name: product.name || "",
                code: product.code || "",
                unit: product.unit || "",
                price: product.price || "",
                sku: product.sku || "",
                stock: product.stock || "",
                brand: product.brand || "",
                product_category: product.product_category.name || "",
                // TODO: Why this is not part of the response?
                available: product.available ? "Disponible" : "Disponible",
              }));
              try {
                json = { data: dataWithId, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurants-invitations":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/restaurant-invitations?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page +
                (params.filter.search ? "&search=" + params.filter.search : "")
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { data } = clientData;
              try {
                json = { data, total: clientData.meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "suppliers-invitations":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/suppliers?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page +
                (params.filter.search
                  ? "&search=" + params.filter.search
                  : "") +
                (params.filter.onboarded
                  ? "&filter.onboarded=" + params.filter.onboarded
                  : "") +
                (params.filter.only_unassigned_to_restaurant
                  ? "&filter.only_unassigned_to_restaurant=" +
                    params.filter.only_unassigned_to_restaurant
                  : "") +
                "&sortBy=created_at:DESC"
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { data } = clientData;
              try {
                json = { data, total: clientData.meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurants-user-invitations":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/restaurant-user-invitations?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page +
                (params.filter.search ? "&search=" + params.filter.search : "")
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { data } = clientData;
              try {
                json = { data, total: clientData.meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "suppliers-user-invitations":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${
                process.env.REACT_APP_API_URL +
                "/supplier-user-invitations?limit=" +
                params.pagination.perPage +
                "&page=" +
                params.pagination.page +
                (params.filter.search ? "&search=" + params.filter.search : "")
              }`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const { data } = clientData;
              try {
                json = { data, total: clientData.meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      default:
        return dataProvider.getList(resource, params);
    }
  },
  getOne: (resource, params) => {
    switch (resource) {
      case "suppliers":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(process.env.REACT_APP_API_URL + `/suppliers/${params.id}`)
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              try {
                json = { data: res };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "products":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`${process.env.REACT_APP_API_URL + "/products/" + params.id}`)
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const data = clientData;

              try {
                json = { data };
              } catch (e) {}

              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "meta-products":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`${process.env.REACT_APP_API_URL + "/meta-products/" + params.id}`)
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const data = clientData;

              try {
                json = { data };
              } catch (e) {}

              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurants":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${process.env.REACT_APP_API_URL + "/restaurants/" + params.id}`
            )
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const data = clientData;
              try {
                json = { data };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "orders":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`${process.env.REACT_APP_API_URL + "/orders/" + params.id}`)
            .then(({ status, statusText, headers, data: clientData }) => {
              let json;
              const data = clientData;

              // Add the review items to the order_lines
              data.order_lines = data.order_lines.map((order_line) => {
                const matchingReview = data.review?.items?.find(
                  (review) => review.productId === order_line.product_id
                );
                return { ...order_line, review: matchingReview };
              });

              try {
                json = { data };
              } catch (e) {}

              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      default:
        return dataProvider.getOne(resource, params);
    }
  },
  create: (resource, params) => {
    switch (resource) {
      case "suppliers":
        return new Promise((resolve, reject) => {
          axiosInstance
            .post(
              process.env.REACT_APP_API_URL + "/registrations/suppliers/",
              params.data
            )
            .then(({ status, statusText, headers, data }) => {
              let json;
              const currentDate = new Date().toLocaleDateString();
              try {
                json = {
                  data: {
                    ...params.data,
                    createdAt: currentDate,
                    updatedAt: currentDate,
                  },
                };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "products":
        return new Promise((resolve, reject) => {
          axiosInstance
            .post(process.env.REACT_APP_API_URL + "/products/", params.data)
            .then(({ status, statusText, headers, data }) => {
              let json;
              try {
                json = { data };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "favorite-products":
        const url = getApiUrl(Endpoints.FAVORITE_PRODUCTS);
        return new Promise((resolve, reject) => {
          axiosInstance
            .post(url, {
              product_ids: params.data.product_ids,
              restaurant_id: params.data.restaurant_id,
            })
            .then(({ status, statusText, headers, data }) => {
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (data && data.message) || "Unknown error",
                    status,
                    data
                  )
                );
              }
              if (!data.id) {
                // React admin expects an id in the response. We generate a random one.
                data.id = params.data.id || Date.now().toString();
              }
              return resolve({ data });
            })
            .catch((error) => {
              // If the request was made and the server responded with a status code
              // that falls out of the range of 2xx, error.response will be available.
              if (error.response) {
                reject(
                  new HttpError(
                    (error.response.data && error.response.data.message) ||
                      "Unknown error",
                    error.response.status,
                    error.response.data
                  )
                );
              } else {
                // Something happened in setting up the request that triggered an Error
                reject(new HttpError(error.message || "Unknown error"));
              }
            });
        });
      default:
        return dataProvider.create(resource, params);
    }
  },
  update: (resource, params) => {
    switch (resource) {
      case "suppliers":
        const currentData = diff(params.previousData, params.data);
        delete currentData.restaurants;
        delete currentData["supplier_categories"];
        return new Promise((resolve, reject) => {
          axiosInstance
            .patch(
              process.env.REACT_APP_API_URL + "/suppliers/" + params.id,
              currentData
            )
            .then(({ status, statusText, headers, data }) => {
              let json;
              const currentDate = new Date().toLocaleDateString();
              try {
                json = {
                  id: params.id,
                  data: { ...params.data, updatedAt: currentDate },
                  previousData: params.previousData,
                };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "products":
        return new Promise((resolve, reject) => {
          axiosInstance
            .patch(
              process.env.REACT_APP_API_URL + "/products/" + params.id,
              diff(params.previousData, params.data)
            )
            .then(({ status, statusText, headers, data }) => {
              let json;
              try {
                json = {
                  id: params.id,
                  data,
                  previousData: params.previousData,
                };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "meta-products":
        return new Promise((resolve, reject) => {
          axiosInstance
            .patch(
              process.env.REACT_APP_API_URL + "/meta-products/" + params.id,
              diff(params.previousData, params.data)
            )
            .then(({ status, statusText, headers, data }) => {
              let json;
              try {
                json = {
                  id: params.id,
                  data,
                  previousData: params.previousData,
                };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "orders":
        return new Promise((resolve, reject) => {
          axiosInstance
            .patch(
              process.env.REACT_APP_API_URL + "/orders/" + params.id,
              diff(params.previousData, params.data)
            )
            .then(({ status, statusText, headers, data }) => {
              let json;
              try {
                json = {
                  id: params.id,
                  data: { ...params.data },
                  previousData: params.previousData,
                };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurants":
        return new Promise((resolve, reject) => {
          axiosInstance
            .patch(
              process.env.REACT_APP_API_URL + "/restaurants/" + params.id,
              diff(params.previousData, params.data)
            )
            .then(({ status, statusText, headers, data }) => {
              let json;
              try {
                json = {
                  id: params.id,
                  data: { ...params.data },
                  previousData: params.previousData,
                };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      default:
        return dataProvider.update(resource, params);
    }
  },
  delete: (resource, params) => {
    switch (resource) {
      case "suppliers":
        return new Promise((resolve, reject) => {
          axiosInstance
            .put(process.env.REACT_APP_API_URL + "/suppliers/" + params.id, {
              ...params.data,
              status: "DELETED",
            })
            .then(({ status, statusText, headers, data }) => {
              let json;
              try {
                json = {
                  data: { id: params.id },
                };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "products":
        return new Promise((resolve, reject) => {
          axiosInstance
            .delete(process.env.REACT_APP_API_URL + "/products/" + params.id)
            .then(({ status, statusText, headers, data }) => {
              let json;
              try {
                json = {
                  data: { id: params.id },
                };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "favorite-products":
        const url = getApiUrl(Endpoints.FAVORITE_PRODUCTS);
        return new Promise((resolve, reject) => {
          axiosInstance
            .delete(url, {
              data: {
                product_ids: params.data.product_ids,
                restaurant_id: params.data.restaurant_id,
              },
            })
            .then(({ status, statusText, headers, data }) => {
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (data && data.messages) || statusText,
                    status,
                    data
                  )
                );
              }
              return resolve({ data: { id: params.id } });
            })
            .catch((error) => {
              reject(error);
            });
        });

      default:
        return dataProvider.delete(resource, params);
    }
  },
  getManyReference: (resource, params) => {
    switch (resource) {
      case "restaurants":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              `${process.env.REACT_APP_API_URL}/restaurants?limit=${params.pagination.perPage}&page=${params.pagination.page}`
            )
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              try {
                json = { data, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "orders":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              process.env.REACT_APP_API_URL +
                `/orders?filter.supplier_id=${params.id}&limit=${params.pagination.perPage}&page=${params.pagination.page}` +
                (params.sort
                  ? `&sortBy=${params.sort.field}:${params.sort.order}`
                  : "")
            )
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              try {
                json = { data, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurant-supplier":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              process.env.REACT_APP_API_URL +
                `/restaurants-suppliers?filter.restaurant_id=${params.id}&limit=${params.pagination.perPage}&page=${params.pagination.page}`
            )
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              console.log({ data})
              const dataWithId = data.map((relation) => ({
                ...relation,
                id: `${relation.supplier_id}|${relation.restaurant_id}|${relation.chat_channel_url}`,
              }));
              try {
                json = { data: dataWithId, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "meta-products-products":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              process.env.REACT_APP_API_URL +
                `/meta-products/${params?.id}/products
                  ${params.filter.search
                    ? "?search=" + params.filter.search
                    : ""
                  }
                `
            )
            .then(({ status, statusText, data }) => {
              let json;
              try {
              console.log({ data})
                json = { data, total: data?.length };
              } catch (e) {
                return reject(new Error('Failed to parse response'));
              }
      
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurant-supplier-alt":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              process.env.REACT_APP_API_URL +
                `/restaurants-suppliers?filter.supplier_id=${params.id}&limit=${params.pagination.perPage}&page=${params.pagination.page}`
            )
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              const dataWithId = data.map((relation) => ({
                ...relation,
                id: `${relation.supplier_id}|${relation.restaurant_id}|${relation.chat_channel_url}`,
              }));
              try {
                json = { data: dataWithId, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurant-user-invitations":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              process.env.REACT_APP_API_URL +
                `/restaurant-user-invitations?filter.restaurant_id=${params.id}&limit=${params.pagination.perPage}&page=${params.pagination.page}`
            )
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              const dataWithId = data.map((relation) => ({
                ...relation,
                id: relation.supplier_id,
              }));
              try {
                json = { data: dataWithId, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "restaurant-invitations":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              process.env.REACT_APP_API_URL +
                `/restaurant-invitations?filter.supplier_id=${params.id}&limit=${params.pagination.perPage}&page=${params.pagination.page}`
            )
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              const dataWithId = data.map((relation) => ({
                ...relation,
                id: relation.supplier_id,
              }));
              try {
                json = { data: dataWithId, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "supplier-catalog":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(
              process.env.REACT_APP_API_URL +
                Endpoints.SUPPLIER_MASTER_CATALOG +
                `?filter.supplier_id=${params.id}&limit=${
                  params.pagination.perPage
                }&page=${params.pagination.page}${
                  params.filter.search ? "&search=" + params.filter.search : ""
                }`
            )
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              const dataWithId = data.map((product) => ({
                id: product.id,
                product_thumbnail: product.product_thumbnail || null,
                name: product.name || "",
                code: product.code || "",
                unit: product.unit || "",
                price: product.price || "",
                sku: product.sku || "",
                stock: product.stock || "",
                brand: product.brand || "",
                product_category: product.product_category.name || "",
                updated_at: product.updated_at || "",
                // TODO: Why this is not part of the response?
                available: product.available ? "Disponible" : "Disponible",
              }));
              try {
                json = { data: dataWithId, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      default:
        return dataProvider.getManyReference(resource, params);
    }
  },
  getMany: (resource, params) => {
    switch (resource) {
      case "restaurants":
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`${process.env.REACT_APP_API_URL + "/restaurants/"}`)
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              const { meta, data } = res;
              try {
                json = { data, total: meta.totalItems };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError(
                    (json && "Unknown error") || statusText,
                    status,
                    json
                  )
                );
              }
              return resolve(json);
            });
        });
      case "favorite-products":
        const limit = params.pagination.limit || 100;
        const url = getApiUrl(Endpoints.FAVORITE_PRODUCTS, {
          "filter.supplier_id": params.supplier_id,
          "filter.restaurant_id": params.restaurant_id,
          limit,
          page: params.pagination.page,
          search: "",
          sortBy: "name:ASC",
        });
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(url)
            .then(({ status, statusText, headers, data: res }) => {
              let json;
              try {
                const { data } = res;
                json = { data };
              } catch (e) {}
              if (status < 200 || status >= 300) {
                return reject(
                  new HttpError("Unknown error", status, json || {})
                );
              }
              return resolve(json);
            });
        });
      default:
        return dataProvider.getManyReference(resource, params);
    }
  },
  uploadProductImage: (resource, params) => {
    switch (resource) {
      case "file-uploads":
        const url = getApiUrl(Endpoints.UPLOAD_PRODUCT_IMAGE);
        const data = new FormData();
        const file = params.file;

        if (file) {
          data.append("file", file);
        }

        return new Promise((resolve, reject) => {
          axiosInstance
            .post(url, data)
            .then((response) => {
              const { status, data } = response;

              if (status < 200 || status >= 300) {
                return reject(new HttpError("Unknown error", status, data));
              }
              return resolve({ data });
            })
            .catch((error) => {
              console.error("Error:", error);
              new HttpError(error);
            });
        });
      default:
        return dataProvider.uploadImage(resource, params);
    }
  },
  downloadCsvCatalog: (resource, params) => {
    return new Promise((resolve, reject) => {
      const url = getApiUrl(
        `${Endpoints.DOWNLOAD_CATALOG}/${params.supplier_id}`
      );
      axiosInstance
        .get(url, {
          responseType: "arraybuffer",
          headers: {
            Accept: "text/csv",
          },
        })
        .then(({ status, statusText, data }) => {
          if (status < 200 || status >= 300) {
            return reject(new Error(statusText));
          }

          const filename =
            params.supplier_name + " - Catálogo de productos.csv";

          const blob = new Blob([data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
