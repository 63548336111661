import React, { useState } from "react";
import {
  Button,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  List,
  ReferenceManyField,
  SaveButton,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useDataProvider,
  useNotify,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { Box, Grid } from "@material-ui/core";

const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    record = { [source]: record };
  }
  return (
    <ImageField
      record={record}
      source={source}
      sx={{
        "& img": {
          minWidth: 400,
          minHeight: 400,
          maxWidth: 400,
          maxHeight: 400,
          objectFit: "fill",
        },
      }}
    />
  );
};

const ProductImageInput = ({ setLoading }) => {
  const formContext = useFormContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleImageUpload = async (file) => {
    setLoading(true);
    dataProvider
      .uploadProductImage("file-uploads", { file })
      .then((response) => {
        const { original } = response?.data;
        formContext.setValue("picture_url", original);
        setLoading(false);
      })
      .catch((error) => {
        notify("Error al subir la imagen:" + error, "warning");
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <>
      <h4>Imagen:</h4>
      <ImageField
        source="picture_url"
        sx={{
          "& img": {
            minWidth: 200,
            minHeight: 200,
            maxWidth: 200,
            maxHeight: 200,
            objectFit: "fill",
          },
        }}
      />
      <ImageInput
        source="preview"
        label="Subir imagen de producto"
        placeholder={<p>Arraste la foto de su producto o busque el archivo</p>}
        sx={{ width: 400 }}
        onChange={handleImageUpload}
      >
        <PreviewImage source="src" />
      </ImageInput>
    </>
  );
};

const CustomToolbar = ({ loading }) => (
  <Toolbar>
    <SaveButton disabled={loading} />
  </Toolbar>
);

export const MetaProductsList = (props) => {
  const filters = [
    <TextInput label="Buscar" source="search" alwaysOn />,
  ];
  return (
    <List {...props} filters={filters}>
      <Datagrid>
        <ImageField
          source="picture_url"
          title="name"
          label="Imagen"
        />
        <TextField source="sku" label="SKU" sortable={false} />
        <TextField source="name" label="Nombre del producto" sortable={false} />
        <TextField source="unit" label="Unidad" sortable={false} />
        <TextField
          source="product_category_id"
          label="Categoría"
          sortable={false}
        />
        <DateField source="created_at" label="Creado el" sortable={false} />
        <DateField
          source="updated_at"
          label="Actualizado el"
          sortable={false}
        />
        <ShowButton label="Ver" />
        <EditButton label="Editar" />
      </Datagrid>
    </List>
  );
};

export const MetaProductsEdit = (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar loading={loading} />}>
        <Grid container spacing={6}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <h4>Detalles del producto:</h4>
            <TextInput source="id" readOnly />
            <TextInput source="name" readOnly label="Nombre" />
            <TextInput source="brand"readOnly label="Marca" />  
            <TextInput source="code" readOnly label="Código" />
            <TextInput source="unit_name" readOnly label="Unidad" /> 
            <TextInput source="sku" readOnly label="SKU" />  

          </Grid>
          <Grid item md={6}>
            <ProductImageInput setLoading={setLoading} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const MetaProductsShowTable = (props) => (
  
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Nombre" />
      <TextField source="unit" label="Unidad" />
      <TextField source="currency_code" label="Moneda" defaultValue="CRC" />
      <TextField source="sku" label="SKU" />
      <TextField source="price" label="Precio" />
      <TextField source="stock" label="Stock" />
      <TextField source="brand" label="Marca" />
      <TextField source="supplier_id" label="Id del proveedor" />
      <TextField source="product_category_id" label="Id de categoría" />
    </SimpleShowLayout>
  </Show>
);

const PostShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <TextField
      source="name"
      label="Nombre"
      style={{ flex: 1, fontSize: 20, fontWeight: "bold" }}
    />
    <Button color="primary" onClick={() => null}>
      Catálogo master
    </Button>
  </TopToolbar>
);


export const MetaProductsShow = (props) => {
  return (
  <Show actions={<PostShowActions />} {...props} title={"Restaurante"}>
  <TabbedShowLayout
    {...props}
    tabs={<TabbedShowLayoutTabs variant="fullWidth" scrollButtons="auto" />}
  >

    <Tab label="Información" role='tab' >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Box style={{ flex: 1, placeContent: "flex-start" }}>
          <span>Información</span>
          <SimpleShowLayout>
      <TextField source="name" label="Nombre" />
      <TextField source="unit" label="Unidad" />
      <TextField source="sku" label="SKU" />
      <TextField source="brand" label="Marca" />
    </SimpleShowLayout>
        </Box>
      </Box>
    </Tab>
    <Tab label="Productos" path="products" >
    <ReferenceManyField
        reference="meta-products-products"
        target="id"
      >
          <Datagrid>
          <ImageField
          source="product_thumbnail.thumb_url"
          title="name"
          label="Imagen"
        />
            <TextField source="name" label="Nombre" />
            <TextField source="unit" label="Unidad" /> 
            <TextField source="price" label="Precio" />
            <TextField source="presentation" label="Presentación" />
          </Datagrid>
        </ReferenceManyField>
    </Tab>
  </TabbedShowLayout>
</Show>
  );
};

